import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import './sign.scss';

import Textfield from "../../components/textfield";
import Button, { ButtonRouter } from "../../components/button";
import Container from "../../components/container";
import useGlobal from "../../../store";
import VerifyCodeField from "../../components/verify.code.field";

const In = () => {

  const navigate = useNavigate();
  const redirect = () => navigate('/');

  const [ globalState, globalActions ] = useGlobal();
  const { sign } = globalActions;

  const [ error, setError ] = useState("");
  const [ email, setEmail ] = useState("");

  const [ isCodeSended, setIsCodeSended ] = useState( false );

  const isFormValidated = ( email.indexOf('@') !== -1 && email.indexOf('.') !== -1 );

  const token = cookie.load('token');

  useEffect(() => { 
      
    if ( token ) { 
      
      (async () => {

        const tokenIsActual = await sign.Check( token );
        tokenIsActual && redirect();

      })();
    
    }

  }, []);

  useEffect(() => { error && setError( false ); }, [ email ]);

  const handleSendCode = () => { 
    
    error && setError( false );
    sign.In({ email, onSuccess: () => setIsCodeSended( true ), onError: setError }) 
  
  };

  const handleVerifyCode = code => {
    
    error && setError( false );
    sign.In({ email, code, onSuccess: redirect, onError: setError });
  
  }

  return (

    <Container>

      <div className = "sign">

        <div className = "sign__content">

          <div className = "logo" />
          
          <hr />

          { !isCodeSended

            ? <div>

                <Textfield

                  title = "Электронная почта"
                  type = "email"
                  value = { email }
                  set = { setEmail }
                  placeholder = "Электронная почта"
                  onKeyUp = { e => ( e.key === 'Enter' && isFormValidated ) && handleSendCode() }

                />

                { error.length > 0 && <div className = "error">{ error }</div> }

                <hr />

                <Button
            
                  action = { handleSendCode }
                  disabled = { !isFormValidated }

                >

                  Войти
                  
                </Button>

                <p className="text-center pointer" onClick = { () => { navigate('/signup') } }>у меня нет аккаунта</p>

                <ButtonRouter url = "/signup">Зарегистрироваться</ButtonRouter>

              </div>

            : <VerifyCodeField

                resendCode = { handleSendCode }
                verifyCode = { handleVerifyCode }
                setError = { setError }
                error = { error }

              />
              
          }
          
        </div>

      </div>

    </Container>

  );

}

export default In;