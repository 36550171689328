import { post } from 'axios';
import API from '../../settings/api'
import cookie from 'react-cookies';
import { minutesToMiliseconds } from '../../scripts/helpers/time';

const disableLogout = false;

export async function Up( store, { email, phone, password, repeat, code = undefined, onSuccess = () => {}, onError = () => {} }) {

  try {

    const { data } = await post( API.sign.up, { email, phone, password, repeat, code } );

    console.log(`signUp data:`, data);

    if ( !data ) { throw('Нет соединения с сервером'); }
    if ( !data.success ) { throw( data?.text ); }

    cookie.save(
      
      'token', 
      data.token, 
      { 
        
        path: '/',
        expires: new Date(new Date().getTime() + 1000 * 3600 * 24 * 365),
        secure: true,

      }
    
    );

    onSuccess();
        
  } catch ( err ) {

    console.error( err );
    onError( err );

  }
  
}

export async function In( store, { email, code = undefined, onSuccess = () => {}, onError = () => {} }) {

  try {

    const { data } = await post( API.sign.in, { email, code } );

    console.log(`signIn data:`, data);

    if ( !data ) { throw('Нет соединения с сервером'); }
    if ( !data.success ) { throw( data?.text ); }

    cookie.save(
      
      'token', 
      data.token, 
      { 
        
        path: '/',
        expires: new Date(new Date().getTime() + 1000 * 3600 * 24 * 365),
        secure: true,

      }
    
    );
    
    const expirationTime = new Date();
    expirationTime.setTime(expirationTime.getTime() + minutesToMiliseconds( 15 ) ); // Время истечения через 15 минут
    
    cookie.save('wait_for_show_msg_from_admin', true, { path: '/', expires: expirationTime });

    onSuccess();

  } catch ( err ) {

    console.error( err );
    onError( err );

  }
  
}

export async function Check( store, token, isSubscribeNeed = false, navigate = () => {}, withoutLogout = false ) {
  
  try {

    const { status, data } = await post( API.sign.check, { token: token } );

    console.log(`check data: ${status}`, data);

    if ( !data.success ) { throw( data.error ); }


    if ( data.user.name && store.state.user_name === "" ) {
      
      const script = document.createElement('script');
      script.src = 'https://code.jivo.ru/widget/aJNBwO7zRw';
      script.async = true;
      document.body.appendChild(script);

    }

    store.setState({ 

      user_name: data.user.name,
      user_tariff: data.tariff,
      showHelpPopup: data.showhelp,
      disableOpenedHelp: data.showhelp,

    });

    if ( isSubscribeNeed && ( !data.tariff?.active ) ) { navigate('/tariff'); }
    
    if ( data.tariff?.active && data.tariff?.id !== 0 && window.location.pathname === "/tariff" ) { navigate('/'); }

    return true;

  } catch ( err ) {

    if ( !withoutLogout ) {

      console.error(`Попытка разлогина из Check:`, err);
      store.actions.sign.Out();
    
    }

    return false;    

  }
  
}

export async function disableDefaultOpenedHelp( store ) {
  
  try {

    const token = cookie.load('token');
    await post( API.sign.disableDefaultOpenedHelp, { token: token } );
    
  } catch ( err ) {

    return false;    

  }
  
}

export async function Out() {

  try {

    if ( disableLogout ) { alert('LogOut'); return; }

    cookie.remove(`token`);
    window.location = "/signin";

  } catch(err) {

  }

}